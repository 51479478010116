import * as React from "react";
import Seo from "../components/seo";
import { graphql } from "gatsby";
import { Link, Trans, useI18next } from "gatsby-plugin-react-i18next";

// markup
const IndexPage = () => {
  const { languages, originalPath } = useI18next();
  return (
    <>
      <Seo title="Lahardiki" />
      <header id="top">
        {/* <button type="button" id="mainMenuOpen" hidden></button> */}

        <h1>Lahardiki</h1>

        <nav>
          <ul>
            <li>
              <a href="#donate">
                <Trans>donate</Trans>
              </a>
            </li>
            <li>
              <a href="mailto:info@lahardiki.org">
                <Trans>contact us</Trans>
              </a>
            </li>
          </ul>
        </nav>
        <nav>
          <ul className="languages">
            {languages.map((lng) => (
              <li key={lng}>
                <Link activeClassName="active" to={originalPath} language={lng}>
                  {new Intl.DisplayNames(lng, {
                    type: "language",
                    languageDisplay: "standard",
                  }).of(lng)}
                </Link>
              </li>
            ))}
          </ul>
        </nav>
      </header>

      <main>
        <h2>
          <Trans>title</Trans>
        </h2>
        <section>
          <p>
            <Trans>horizontal</Trans>
          </p>
          <p>
            <Trans>directly</Trans>
          </p>
          <p>
            <Trans>personal</Trans>
          </p>
          <p>
            <Trans>refugee support</Trans>
          </p>
          <p>
            <Trans>supplies</Trans>
          </p>
        </section>
        <section>
          <article>
            <Trans>description</Trans>
          </article>
        </section>
        <section>
          <article id="donate">
            <p>info@lahardiki.org</p>
            <p>
              Account holder/Kontoinhaber: elinor Treuhand e.V.
              <br />
              Bank name/Name der Bank: GLS Bank
              <br />
              IBAN: DE48430609677918887700
              <br />
              BIC: GENODEM1GLS
              <br />
              Purpose/Verwendungszweck: (Spende) ELINOR82HFUV
            </p>
          </article>
        </section>
      </main>

      <footer>
        <nav>
          <ul className="languages">
            {languages.map((lng) => (
              <li key={lng}>
                <Link activeClassName="active" to={originalPath} language={lng}>
                  {new Intl.DisplayNames(lng, {
                    type: "language",
                    languageDisplay: "standard",
                  }).of(lng)}
                </Link>
              </li>
            ))}
          </ul>
        </nav>
        <nav>
          <ul>
            <li>
              <a href="#donate">
                <Trans>donate</Trans>
              </a>
            </li>
            <li>
              <a href="mailto:info@lahardiki.org">
                <Trans>contact us</Trans>
              </a>
            </li>
          </ul>
        </nav>
      </footer>
    </>
  );
};

export default IndexPage;

export const query = graphql`
  query IndexQuery($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
